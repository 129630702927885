.CompactCard {
    cursor: pointer;
  }
  /* ExpandedCard */
  .ExpandedCard {
    position: absolute;
    width: 60%;
    height: 70vh;
    z-index: 9;
    left: 24rem;
    top: 10rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 1rem;
  }
  .ExpandedCard > span:nth-of-type(1) {
    color: white;
    font-size: 26px;
    font-weight: bold;
    text-shadow: 0px 0px 15px white;
  }
  .ExpandedCard > span:nth-of-type(2) {
    color: rgb(236, 236, 236);
    font-size: 15px;
  }
  
  .chartContainer {
    width: 70%;
  }
  
  @media screen and (max-width: 1200px) {
    .ExpandedCard {
      top: 2rem;
      height: 45vh;
      left: 6rem;
    }
  }
  
  @media screen and (max-width: 768px) {
    .ExpandedCard {
      top: 8rem;
      height: 50%;
      left: 25px;
      width: 80%;
    }
  }