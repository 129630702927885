/* text editor*/
.rs-picker-default .rs-picker-toggle.rs-btn {
    border-color: orange;
}
.rs-picker-toggle-active, .rs-picker.rs-picker-focused {
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.25);
}
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus, .rs-picker-default:not(.rs-picker-disabled) .rs-btn-active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active {
    border-color: orange;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    box-shadow: inset 0 0 0 1px orange;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: orange;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
    background-color: orange;
}

.rs-calendar-table-cell-in-range::before {
    background-color: rgba(255, 136, 0, 0.2);
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content {
    background-color: rgba(255, 136, 0, 0.2);
    color: orange;
}

.rs-btn-primary {
    background-color: orange;
}

.rs-btn-link {
    color: orange;
}

.rs-btn-primary:disabled, .rs-btn-primary.rs-btn-disabled {
    background-color: orange;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value, .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: orange;
}

.rs-btn-primary:hover, .rs-btn-primary:focus {
    background-color: rgb(99, 56, 0);
}

.rs-btn-link:hover, .rs-btn-link:focus {
    color: orange;
}

.rs-calendar-month-dropdown-year-active {
    color: orange;
}

.rs-calendar-month-view .rs-calendar-header-title-date {
    color: orange;
}

.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
    background-color: orange;
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content:hover {
    background-color: rgb(99, 56, 0);
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
    background-color: rgba(255, 136, 0, 0.2);
    color: orange;
}

.rs-pagination-btn.rs-pagination-btn-active {
    border: 1px solid orange;
    color: orange;
}

.rs-pagination-btn.rs-pagination-btn-active:hover, .rs-pagination-btn.rs-pagination-btn-active:focus {
    border-color: orange;
    color: orange;
    box-shadow: 0 0 0 1px orange;
}

.rs-input:focus, .rs-input:hover:not(:disabled) {
    border-color: orange;
    outline: none;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
    background-color: #ffa50024;
    color: orange;
}