html {
  overflow-x: hidden;
}
body {
  overflow-x: hidden;
  margin: 0;
}

* {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

a:hover, a:focus {
  text-decoration: none;
}


&::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

&::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

&::-webkit-scrollbar-thumb {
  background: #F2994A;
  border-radius: 10px;
}

&::-webkit-scrollbar-thumb:hover {
  background: #F17707;
  border-radius: 10px;
}